import React, { useEffect, useState } from "react";
import "./Hero.css";
import ApiService from "../../network/ApiService";
import { useSelector } from "react-redux";
import engToNepNumber from "../../helpers/EngToNepNumber";

function Feature() {
  const { organizationId } = useSelector((state) => state.organization);
  const [sliderImages, setSliderImages] = useState([]);
  const languageStatus = sessionStorage.getItem("language");
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchOutStanding,
  } = ApiService();

  const getSliderImages = async () => {
    try {
      const response = await fetchSliderImages();
      setSliderImages(response.data.imagesList);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (organizationId) {
      getSliderImages();
    }
  }, [organizationId]);

  const [OutStandingReport, setOutStandingReport] = useState({});
  const [customerNo, setCustomerNo] = useState("");
  const [messageDetail, setMessageDetail] = useState(null);
  const getOutStandingReport = async () => {
    try {
      const response = await fetchOutStanding(customerNo);
      setOutStandingReport(response.data);
      if (Object.keys(response.data).length === 0) {
        languageStatus === 'en' ? setMessageDetail("Customer Detail Not Found...") :
            setMessageDetail("ग्राहक विवरण भेटियन...");
      }
    } catch (error) {
      setOutStandingReport({});
      languageStatus === 'en' ? setMessageDetail("Customer Detail Not Found...") :
          setMessageDetail("ग्राहक विवरण भेटियन...");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (organizationId) {
      getOutStandingReport();
    }
  };

  useEffect(() => {
    if (customerNo.trim() === "") {
      setMessageDetail(null);
    }
  }, [customerNo]);
  return (
    <>
      <main id="main">
        <section id="features" className="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 order-2 order-lg-2">
                <div id="contact" className="contact pb-0">
                  <h4>{ languageStatus === 'en' ? `Outstanding Report` : `बक्यौता विवरण`}</h4>
                  <form
                    onSubmit={(e) => handleSubmit(e)}
                    role="form"
                    className="php-email-form pt-2"
                  >
                    <div className="form-group mt-3">
                      {/* <label htmlFor="">ग्राहकको कोड</label> */}
                      <input
                        type="text"
                        className="form-control"
                        name="customerNo"
                        id="customerNo"
                        placeholder={ languageStatus === 'en' ? `Customer No.` : `ग्राहकको कोड`}
                        required
                        value={customerNo}
                        onChange={(e) => setCustomerNo(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="form-control detail-submit-btn">
                        { languageStatus === 'en' ? `Search` : `खोज्नुहोस`}
                      </button>
                    </div>
                  </form>
                  {Object.keys(OutStandingReport).length > 0 ? (
                    <div className="mt-2">
                      <h4 className="text-center">{ languageStatus === 'en' ? `Customer's Detail` : `ग्राहक विवरण`}</h4>
                      <ul className="list-group bg-light p-3">
                        <li className="list-group-item">
                          { languageStatus === 'en' ? `Name` : `नाम `}: <span>{languageStatus === 'en' ? OutStandingReport.fullName : OutStandingReport.fullNameNp}</span>
                        </li>
                        <li className="list-group-item">
                          { languageStatus === 'en' ? `Address` : `ठेगाना `}: <span>{languageStatus === 'en' ? OutStandingReport.address : OutStandingReport.addressNp}</span>
                        </li>
                        <li
                          className="list-group-item"
                          style={{
                            backgroundColor: "#05C3DD",
                            color: "#fff",
                          }}
                        >
                          { languageStatus === 'en' ? `Amount to be Paid` : `जम्मा भुक्तानी गर्ने रकम` } :
                          <span>
                            {
                              languageStatus === 'en' ?
                                  <>
                                    Rs. { OutStandingReport.totalPayable }
                                  </>
                                  :
                                  <>
                                    रु. { engToNepNumber(OutStandingReport.totalPayable) }
                                  </>
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="text-center">{messageDetail}</div>
                  )}
                </div>
              </div>
              <div className="image col-lg-8 order-1 order-lg-1">
                <div className="portfolio-item filter-app">
                  <div className="portfolio-info">
                    {/* <a
                      // href="assets/img/portfolio/portfolio-1.jpg"
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                    > */}
                    <img
                      src={
                        sliderImages.length > 0
                          ? sliderImages[0]
                          : `assets/images/placeholder.jpg`
                      }
                      className="img-fluid"
                      alt=""
                    />
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Feature;
