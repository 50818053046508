import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../network/ApiService";
import MessageNew from "./MessageNew";

function About() {
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchMessages,
  } = ApiService();

  const { organizationId } = useSelector((state) => state.organization);
  const languageStatus = sessionStorage.getItem("language");
  const [aboutUsData, setAboutUsData] = useState([]);
  const [messages, setMessages] = useState({});
  const [messageDetails, setMessageDetails] = useState({});

  // const fetchStaffMessages = async () => {
  //   try {
  //     const response = await fetchMessages();
  //     setMessages(response.data.mapOfIdAndDisplayLabels);
  //     setMessageDetails(response.data.mapOfIdAndDetails);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // useEffect(() => {
  //   if (organizationId) {
  //     fetchStaffMessages();
  //   }
  // }, [organizationId]);
  const getAboutUs = async () => {
    try {
      const response = await fetchAboutUs();
      setAboutUsData(response.data.aboutUsDetails);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId) {
      getAboutUs();
      // fetchStaffMessages();
    }
  }, [organizationId]);

  useEffect(() => {
    if (aboutUsData.length > 0) {
      aboutUsData.length = 1;
      setAboutUsData(aboutUsData);
    }
  }, [aboutUsData]);

  return (
    <main id="main">
      <section id="about" className="about">
        {aboutUsData.length > 0 ? (
          <div className="container">
            <div className={window.location.pathname === '/about' ? 'section-title sticky_header' : ``}>
              <h2>
                {
                  languageStatus === 'en' ?
                  (aboutUsData[0].header ? aboutUsData[0].header : aboutUsData[0].headerNp)
                      : (aboutUsData[0].headerNp ? aboutUsData[0].headerNp : aboutUsData[0].header)
                }
              </h2>
            </div>

            <div className="d-flex content">
              <div className="col-md-5 mx-1 shadow p-3 mb-5 bg-white rounded">
                <img
                  className="about-image"
                  src={
                    aboutUsData[0].image && aboutUsData[0].image.trim() !== ""
                      ? aboutUsData[0].image
                      : `assets/images/placeholder.jpg`
                  }
                />
              </div>
              <div className="col-md-7 mx-1 shadow p-3 mb-5 bg-white rounded">
                <p
                  className="text-justify card-body"
                  dangerouslySetInnerHTML={parseHtmlToView(
                      (languageStatus === 'en' ? (aboutUsData[0].message !== '' ? aboutUsData[0].message : aboutUsData[0].messageNp) : ((aboutUsData[0].messageNp !== '' && aboutUsData[0].messageNp) ? aboutUsData[0].messageNp : aboutUsData[0].message))
                  )}
                ></p>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </main>
  );
}

export default About;
