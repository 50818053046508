import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    popUpData:null,
};

const popUp = createSlice({
    name:'popUp',
    initialState,
    reducers:{
        setPopUpData: (state,action) => {
            // console.log(action.payload,'redfdfddf');
            return {
                ...state,
                popUpData:action.payload
            }
        }
    }
})

export const {setPopUpData} = popUp.actions;

export default popUp.reducer;
