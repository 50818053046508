import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../network/ApiService";
import "./Popup.css";
import Modal from "react-bootstrap/Modal";

function Popup(props) {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchDownloads,
        fetchPopup,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const {popUpData} = useSelector((state) => state.popUp);
    const [popUpList, setPopUpList] = useState([]);
    const languageStatus = sessionStorage.getItem("language");
    const getPopUpLists = async () => {
        try {
            const response = await fetchPopup();
            setPopUpList(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    // useEffect(() => {
    //   if (organizationId) {
    //     getPopUp();
    //   }
    // }, [organizationId]);
    useEffect(() => {
        // if (popUpData && popUpData.length) {
        //   setPopUpList(popUpData);
        // }
        if (organizationId) {
            getPopUpLists();
        }
    }, [organizationId]);
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
      {popUpList && popUpList.length > 0 ? (
          <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              // centered
          >
          <Modal.Header closeButton>
            <Modal.Title>{languageStatus === 'en' ? (popUpList[0].header ?? popUpList[0].headerNp) : (popUpList[0].headerNp ?? popUpList[0].header)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="card-header text-center">{popUpList[0].header}</div> */}
              <div className="card-body pt-0 modal-card-body">
              <div
                  className="card-text mt-2"
                  dangerouslySetInnerHTML={parseHtmlToView(
                      languageStatus === 'en' ? (popUpList[0].message ?? popUpList[0].messageNp) : (popUpList[0].messageNp ?? popUpList[0].message)
                  )}
              ></div>
                  {
                      (Array.isArray(popUpList[0].imageUrlList))
                          ?
                          (popUpList[0].imageUrlList).length > 0
                              ?
                              (
                                  popUpList[0].imageUrlList).map((image, index) => {
                                      return (
                                          <>
                                <span key={`notice_` + index}>
                                  <img
                                      src={image}
                                      className="notice-img mt-4"
                                      alt="notice"
                                  />
                                </span>
                              </>
                                      )
                                  }
                              )
                              : null
                          : <>
                    {popUpList[0].imageUrl ? (
                        <img
                            src={popUpList[0].imageUrl}
                            className="notice-img mt-4"
                            alt="notice"
                        />
                    ) : null
                    }
                        < />

                  }
                  {/*{*/}
                  {/*  Array.isArray(popUpList) ?*/}
                  {/*      popUpList[0].imageUrlList.map((image, index) => {*/}
                  {/*      return (*/}
                  {/*          <>*/}
                  {/*          <img*/}
                  {/*              // src={image}*/}
                  {/*              className="notice-img mt-4"*/}
                  {/*              alt="notice"*/}
                  {/*          />*/}
                  {/*          </>*/}
                  {/*      )*/}
                  {/*    })(*/}
                  {/*) : null}*/}
            </div>
                      </Modal.Body>
                      </Modal>
      ) : null}
    </>
    );
}

export default Popup;
