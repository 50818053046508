import { autoBatchEnhancer } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../../network/ApiService";
import "./Team.css";
import Staff from "./Staff";
import engToNepNumber from "../../../helpers/EngToNepNumber";

function Team() {
  const [committeeList, setCommitteeList] = useState([]);
  const { organizationId } = useSelector((state) => state.organization);
  const languageStatus = sessionStorage.getItem("language");
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
  } = ApiService();

  const getStaffList = async () => {
    try {
      const response = await fetchStaffs();
      // console.log(response, "res");
      setCommitteeList(response.data.committee);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (organizationId) {
      getStaffList();
    }
  }, [organizationId]);

  return (
    <div>
      <section id="team" className="team section-padding">
        <div className="container">
          <div className="col-lg-12 col-12 text-center mb-4">
            <h2>{languageStatus === 'en' ? `Members` :`सदस्यहरु`} </h2>
          </div>

          {committeeList.length > 0
            ? committeeList.map((data, index) => {
                return (
                  <div className="card mb-4 board-section" key={index}>
                    <div className="card-header">
                      <h3 className="card-text">{languageStatus === 'en' ? (data.name ?? data.nameNp) : (data.nameNp ?? data.name)}</h3>
                    </div>
                    <div className="card-body">
                      <div className="container mb-5 board-members">
                        {data.subHeaders && data.subHeaders.length > 0 ? (
                          <div className="row">
                            {data.subHeaders.map((sub, item) => {
                              return (
                                <>
                                  {!sub.staffs ? (
                                    <div
                                      className="col-lg-3 col-md-6 align-items-stretch aos-init aos-animate"
                                      data-aos="fade-up"
                                      data-aos-delay="100"
                                      key={item}
                                    >
                                      <div className="member">
                                        <div className="member-img">
                                          <img
                                            src={
                                              sub.photoUrl
                                                ? sub.photoUrl
                                                : "assets/images/placeholder.jpg"
                                            }
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </div>
                                        <div className="member-info">
                                          <h4 className="staff-name">
                                            {languageStatus === 'en' ? (sub.name ?? sub.nameNp) : (sub.nameNp ?? sub.name)}
                                          </h4>
                                          {/*{sub.staffDesignation ? (*/}
                                            <>
                                              <span>
                                                {languageStatus === 'en' ? sub.staffDesignation : sub.staffDesignationNp}
                                              </span>
                                            </>
                                          {/*) : null}*/}
                                          {sub.staffContactNumber1 ? (
                                            <>
                                              <span>
                                                {languageStatus === 'en' ? sub.staffContactNumber1 : engToNepNumber(sub.staffContactNumber1)}
                                              </span>
                                            </>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  ) : sub.staffs && sub.staffs.length < 1 ? (
                                    <div className="col-md-6" key={item}>
                                      <div className="card-header">
                                        {languageStatus === 'en' ? (sub.name ?? sub.nameNp) : (sub.nameNp ?? sub.name)}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-md-6" key={item}>
                                      <div className="card">
                                        <div className="card-header">
                                          {languageStatus === 'en' ? (sub.name ?? sub.nameNp) : (sub.nameNp ?? sub.name)}
                                        </div>
                                        {sub.staffs && sub.staffs.length > 0 ? (
                                          <div className="card-body">
                                            <div className="container">
                                              <div className="row">
                                                {sub.staffs.map(
                                                  (staff, key) => {
                                                    return (
                                                      <div
                                                        className="col-lg-6 col-md-6 align-items-stretch aos-init aos-animate"
                                                        data-aos="fade-up"
                                                        data-aos-delay="100"
                                                        key={key}
                                                      >
                                                        <Staff staffs={staff} />
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </section>
    </div>
  );
}

export default Team;
