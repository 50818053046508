import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ApiService from "../../../network/ApiService";
import Service from "./Service";

function ServiceDetail() {
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchMessages,
    fetchServiceDetailById,
    fetchServices,
  } = ApiService();

  const { organizationId } = useSelector((state) => state.organization);
  const [serviceDetail, setServiceDetail] = useState([]);
  const params = useParams();
  const serviceId = params.serviceId;

  const [serviceList, setServiceList] = useState([]);
  const [slicedService, setSlicedService] = useState([]);
  const getServices = async () => {
    try {
      const response = await fetchServices();
      setServiceList(response.data.messageWithImg);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getServiceDetails = async () => {
    try {
      const response = await fetchServiceDetailById(serviceId);
      setServiceDetail(response.data.messageWithImg);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId && serviceId) {
      getServiceDetails();
      getServices();
    }
  }, [organizationId, serviceId]);

  useEffect(() => {
    if (serviceList.length > 0 && serviceId) {
      const newList = [...serviceList];
      const filtered = newList.filter((item) => {
        return item.id !== Number(serviceId);
      });

      if (filtered.length > 3) {
        filtered.length = 3;
      }
      setSlicedService(filtered);
    }
  }, [serviceList]);

  return (
    <section id="services" className="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-12 mt-3">
            <div className="section-title">
              <h2>हाम्रो सेवाहरु</h2>
            </div>
            {serviceDetail.length > 0 ? (
              <div className="news-block">
                <div className="news-block-top">
                  <a href="#">
                    <img
                      src={
                        serviceDetail[0].imageUrl !== "n/a"
                          ? serviceDetail[0].imageUrl
                          : `assets/images/placeholder.jpg`
                      }
                      className="news-image img-fluid"
                      alt=""
                    />
                  </a>

                  {/* <div className="news-category-block">
                  <a href="#" className="category-block-link">
                    जीवनशैली,
                  </a>

                  <a href="#" className="category-block-link">
                    पिउने पानी
                  </a>
                </div> */}
                </div>

                <div className="news-block-info">
                  <div className="d-flex mt-2">
                    {/*<div className="news-block-date">*/}
                    {/*  <p>*/}
                    {/*    <i className="bi-calendar4 custom-icon me-1"></i>*/}
                    {/*    {serviceDetail[0] ? serviceDetail[0].newsDate : null}*/}
                    {/*  </p>*/}
                    {/*</div>*/}

                    {/*<div className="news-block-author mx-5">*/}
                    {/*  <p>*/}
                    {/*    <i className="bi-person custom-icon me-1"></i>*/}
                    {/*    By*/}
                    {/*    {serviceDetail[0] ? serviceDetail[0].createdBy : null}*/}
                    {/*  </p>*/}
                    {/*</div>*/}

                    {/* <div className="news-block-comment">
                    <p>
                      <i className="bi-chat-left custom-icon me-1"></i>
                      32 Comments
                    </p>
                  </div> */}
                  </div>

                  <div className="news-block-title mb-2">
                    <h4>
                      <a href="#" className="news-block-title-link">
                        {serviceDetail[0] ? serviceDetail[0].header : null}
                      </a>
                    </h4>
                  </div>

                  <div className="news-block-body">
                    <p
                      dangerouslySetInnerHTML={parseHtmlToView(
                        serviceDetail[0].message
                      )}
                    ></p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="col-lg-4 col-12">
            {/* <h5 className="mt-5 mb-3">भर्खरको समाचार</h5> */}
            {slicedService.length > 0 ? (
              <div className="container mt-3">
                <div className="section-title">
                  <h2>अन्य सेवाहरु</h2>
                </div>

                <div className="row">
                  {slicedService.map((data, index) => {
                    return (
                      <div
                        className="col-lg-12 col-md-6 d-flex align-items-stretch mt-3"
                        key={index}
                      >
                        <div className="icon-box">
                          <Link to={`/service-detail/${data.id}`}>
                            <img
                              src={
                                data.imageUrl !== "n/a"
                                  ? data.imageUrl
                                  : `assets/img/features.jpg`
                              }
                              className="news-images"
                            />
                            <h4>{data.header}</h4>
                            <p
                              dangerouslySetInnerHTML={parseHtmlToView(
                                data.message
                                  .split("...")
                                  .join("")
                                  .substring(0, 100)
                              )}
                            ></p>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceDetail;
