import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ApplicationHelper from "../../../helpers/ApplicationHelper";
import ApiService from "../../../network/ApiService";

function News(props) {
  const { organizationId } = useSelector((state) => state.organization);
  const languageStatus = sessionStorage.getItem("language");
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
  } = ApiService();

  const { shuffle } = ApplicationHelper();

  const [newsList, setNewsList] = useState([]);
  const [splicedNews, setSplicedNews] = useState([]);
  const getNewsList = async () => {
    try {
      const response = await fetchNewsUpdates();
      if(window.location.pathname === '/'){
        setNewsList((response.data.aboutUsNewsAndImg).filter((a, b) => {
          return b < 4;
        }))
      }else{
        setNewsList(response.data.aboutUsNewsAndImg);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId) {
      getNewsList();
    }
  }, [organizationId]);

  useEffect(() => {
    // if (splicedNews.length === 0) {
    if (props.newsDetailId) {
      const newNewsList = [...newsList];
      const filteredList = newNewsList.filter((news) => {
        return news.newsId !== Number(props.newsDetailId);
      });

      if (filteredList.length > 3) {
        filteredList.length = 3;
      }
      // console.log(shuffle(filteredList),'shuff');
      // const newFilteredList = shuffle(filteredList);
      setSplicedNews(filteredList);
    }
    // }
  }, [newsList,props.newsDetailId]);
  // const IMAGEURL = process.env.REACT_APP_IMAGE_URL;

  return (
    <section id="services" className="services">
      {newsList.length > 0 ? (
        <div className="container mt-5">
          <div className="position-relative section-title">
            <h2>{languageStatus === 'en' ? 'News and Notices' : `समाचार र सूचना`}</h2>
            <span className={window.location.pathname === '/' ? '' : 'd-none'}>
              <Link to="/news" className="form-control detail-submit-btn position-absolute top-0 w-auto" style={{ right: '5rem', color: 'white'}}>
                    { languageStatus === 'en' ? `View All` : `सबै हेर्नुहोस्`}
              </Link>
            </span>
          </div>

          <div className="row">
            {newsList.map((data, index) => {
              return (
                <div
                  className="col-lg-3 col-md-6 d-flex align-items-stretch mt-3"
                  key={index}
                >
                  <div className="icon-box">
                    <Link to={`/news-detail/${data.newsId}`} style={{ display: 'flex', flexDirection:'column', alignItems: 'center' }}>
                      <img
                        src={
                          data.imageUrl !== "n/a"
                            ? data.imageUrl
                            : `assets/images/placeholder.jpg`
                        }
                        className="news-images"
                      />
                      <h4>{(languageStatus === 'en' ? (data.header !== '' ? data.header : data.headerNp) : ((data.headerNp !== '' && data.headerNp) ? data.headerNp : data.header))}</h4>
                      {/*<p*/}
                      {/*  dangerouslySetInnerHTML={parseHtmlToView(*/}
                      {/*    (languageStatus === 'en' ? (data.message !== '' ? data.message : data.messageNp) : ((data.messageNp !== '' && data.messageNp) ? data.messageNp : data.message))*/}
                      {/*        .split("...").join("").substring(0, 150)*/}
                      {/*  )}*/}
                      {/*></p>*/}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default News;
