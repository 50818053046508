import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiService from "../../network/ApiService";

function Message() {
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchMessages,
  } = ApiService();

  const { organizationId } = useSelector((state) => state.organization);
  const languageStatus = sessionStorage.getItem("language");
  const [messages, setMessages] = useState([]);
  const [label, setLabel] = useState(null);
  const params = useParams();
  const messageId = params.messageId;
  const getMessage = async () => {
    try {
      const response = await fetchMessages();
      setMessages(response.data.mapOfIdAndDetails[messageId]);
      setLabel(response.data.mapOfIdAndDisplayLabels[messageId]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId && messageId) {
      getMessage();
    }
  }, [organizationId, messageId]);

  return (
    <main id="main">
      <section id="pricing" className="pricing">
        <div className="container">
          <div className="section-title sticky_header">
            {label ? <h2>
              {
                languageStatus === 'en' ?
                    <>
                                                  {label ? `${(label.designation).trim()}'s Speech` : null}
                                                </>
                    :
                    <>
                                                {label ? `${(label.designationNP).trim()}को मन्तव्य` : null}
                                              </>
              }
            </h2> : null}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-6">
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="col-md-12 row box text-start">
                    <div className="col-lg-4">
                      <img
                      className="message-image"
                        src={
                          label && label.profileImg !== "N/A"
                            ? label.profileImg
                            : `../assets/images/placeholder.jpg`
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-lg-8">
                      {/* <h3className={} s="p-0">अध्यक्षको सन्देश</h3> */}
                      {messages.length > 0 ? (
                        <p
                          dangerouslySetInnerHTML={parseHtmlToView(
                              languageStatus === 'en' ? (messages[0].message ?? messages[0].messageNp) : (messages[0].messageNp ?? messages[0].message)
                          )}
                          />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Message;
