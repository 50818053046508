import React from "react";
import {useSelector} from "react-redux";
import "./Hero.css";

function Hero() {
    const {orgDetailData} = useSelector((state) => state.contact);
    const languageStatus = sessionStorage.getItem("language");

    return (
        <section
            id="hero" className="d-flex flex-column justify-content-center fade-in" style={{
            height: "30rem"
        }}
        >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            {/* <img
            className="logo-main"
              src={
                orgDetailData && orgDetailData.logoPath !== "N/A"
                  ? orgDetailData.logoPath
                  : `assets/img/logo.png`
              }
              alt=""
            /> */}
              <p
                  style={{
                      color: "white",
                      fontSize: "40px",
                      marginTop: "10px"
                  }}
              >
                  {
                      orgDetailData ? ( languageStatus === 'en' ? (
                          (orgDetailData.nameEnglish).includes('Mangadh')
                              ? orgDetailData.nameEnglish.split('Mangadh')[0]
                              : orgDetailData.nameEnglish
                          ) : (
                              (orgDetailData.nameNepali).includes('मानगढ')
                                  ? orgDetailData.nameNepali.split('मानगढ')[0]
                                  : orgDetailData.nameNepali
                      ) ) : null
                  }
                  <br />
                  {
                      orgDetailData ? ( languageStatus === 'en'  ?
                          (
                              (orgDetailData.nameEnglish).includes('Mangadh') ? 'Mangadh'+orgDetailData.nameEnglish.split('Mangadh')[1] : ''
                          )
                          :
                          (
                              (orgDetailData.nameNepali).includes('मानगढ') ? 'मानगढ'+orgDetailData.nameNepali.split('मानगढ')[1] : ''
                          )
                      ): null
                      // )
                      //     : ((orgDetailData.nameEnglish).includes('मानगढ')
                      //         ? orgDetailData.nameEnglish.split('मानगढ')[0]
                      //         : orgDetailData.nameEnglish )
                      //     (
                      //         (orgDetailData.nameEnglish).includes('मानगढ') ? 'मानगढ'+orgDetailData.nameEnglish.split('मानगढ')[1] : ''
                      //     )
                  }
                  {/*{orgDetailData ? (languageStatus === 'en' ? (orgDetailData.nameEnglish ?? orgDetailData.nameNepali) : (orgDetailData.nameNepali ?? orgDetailData.nameEnglish)) : null}*/}
                  {/*{ (orgDetailData.nameEnglish).includes('Mangadh') ? orgDetailData.nameEnglish.split('Mangadh')[0] : orgDetailData.nameEnglish }*/}
                  {/*<br />*/}
                  {/*{(orgDetailData.nameEnglish).includes('Mangadh') ? 'Mangadh'+orgDetailData.nameEnglish.split('Mangadh')[1] : '' }*/}
                      {/*// orgDetailData.nameEnglish.replace('Mangadh ', `/n Mangadh /n `)}*/}
                  {/*{ orgDetailData.nameNepali}*/}
              </p>
          </div>
        </div>
      </div>
    </section>
    );
}

export default Hero;
