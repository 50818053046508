import React, { useEffect, useState } from "react";
import Popup from "./includes/Popup";
import About from "./pages/About";
import PaymentGateway from "./pages/eservices/paymentGateway/PaymentGateway";
import Feature from "./pages/Feature";
import Hero from "./pages/Hero";
import HeroNew from "./pages/HeroNew";
import MessageNew from "./pages/MessageNew";
import News from "./pages/news/News";

function Home() {
  const [popupVisible, setPopupVisible] = useState(false);
  const languageStatus = sessionStorage.getItem("language");

  useEffect(() => {
    const popupStatus = sessionStorage.getItem("popup");
    if (!popupStatus) {
      setPopupVisible(true);
      sessionStorage.setItem("popup", 1);
    }
  }, []);

  return (
    <div>
      <News/>
      <Feature />
      <About />
      <PaymentGateway />
      {popupVisible ? (
        <div className="d-flex justify-content-between">
          <div>
            <Popup />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Home;
