import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../../network/ApiService";

function PaymentGateway() {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchPaymentGatewayList,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const [paymentGatewayList, setPaymentGatewayList] = useState([]);
    const languageStatus = sessionStorage.getItem("language");

    const getPaymentGatewayList = async () => {
        try {
            const response = await fetchPaymentGatewayList();
            setPaymentGatewayList(response.data.paymentGateWayDetail);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (organizationId) {
            getPaymentGatewayList();
        }
    }, [organizationId]);

    // const IMAGEURL = process.env.REACT_APP_IMAGE_URL;

    return (
        <section className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-12 text-center mx-auto">
            <h2 className="mb-5">{languageStatus === 'en' ? 'Payment Partners' : `भुक्तानी साझेदार`}</h2>
          </div>
            {paymentGatewayList.length > 0
                ? paymentGatewayList.map((data, index) => {
                    return (
                        <div
                            className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0"
                            key={index}
                        >
                    <div className="featured-block d-flex justify-content-center align-items-center">
                      <a
                          href={
                              data.payementGateWayURL !== "N/A" &&
                              data.payementGateWayURL.trim() !== ""
                                  ? data.payementGateWayURL
                                  : null
                          }
                          target={
                              data.payementGateWayURL !== "N/A" &&
                              data.payementGateWayURL.trim() !== ""
                                  ? "_blank"
                                  : null
                          }
                          className="d-block"
                      >
                        <img
                            src={
                                data.paymentGatewayLogo !== "N/A"
                                    ? data.paymentGatewayLogo
                                    : `assets/images/placeholder.jpg`
                            }
                            className="featured-block-image payment-gateway"
                            alt=""
                        />

                        <p className="featured-block-text">
                          {
                              languageStatus === 'en' ?
                                  (data.paymentGateWayShortName ?? data.paymentGateWayShortNameNp)
                                  : (data.paymentGateWayShortNameNp ?? data.paymentGateWayShortName)
                          }
                        </p>
                      </a>
                    </div>
                  </div>
                    );
                })
                : null}
        </div>
      </div>
    </section>
    );
}

export default PaymentGateway;
