function ApplicationHelper() {
  const shuffle = (list) => {
    // console.log(list,'li');
    for (let i = list.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
    return list;
  };

  return {
    shuffle,
  };
}

export default ApplicationHelper;
