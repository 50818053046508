import axios from "axios";
import { Buffer } from "buffer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { Cookies } from "react-cookie";

// const BASE_URL = "http://10.13.174.143:8082/gis/";

function ApiService() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const USERNAME = process.env.REACT_APP_USERNAME;
  const PASSWORD = process.env.REACT_APP_PASSWORD;

  const { organizationId } = useSelector((state) => state.organization);
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = false;
  // const navigate = useNavigate();

  // axios.defaults.headers.common["auth"]={
  //     username:USERNAME,
  //     password:PASSWORD
  // };
  // const token = 'Basic YWRtaW46YWRtaW4=';
  const token = `${USERNAME}:${PASSWORD}`;
  // const token = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);
  const encodedToken = Buffer.from(token).toString("base64");
  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Basic ${encodedToken}`;
    return config;
  });

  // axios.interceptors.response.use((response)=>{
  //   if (response.status === 200) {
  //     navigate("/", { replace: true });
  //   }
  // });

  const fetchOrgIdByDomainName = async (domain) => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/domainOrgId/${domain}`
    );
    return response;
  };
  // fetch News And Updates

  const fetchNewsUpdates = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/newsAndUpdate/web/${organizationId}`
    );

    return response;
  };
  const fetchContact = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/contactList/${organizationId}`
    );

    return response;
  };
  const fetchAboutUs = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/aboutUs/web/${organizationId}`
    );
    return response;
  };
  const fetchSliderImages = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/sliderImages/${organizationId}`
    );

    return response;
  };
  const fetchStaffs = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/staffCommittee/${organizationId}`
    );

    return response;
  };
  const fetchGalleries = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/organizationGallery/web/${organizationId}`
    );
    return response;
  };

  const fetchDownloads = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/organizationDocument/web/${organizationId}`
    );
    return response;
  };

  const fetchMessages = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/messagesFromStaffs/web/${organizationId}`
    );
    return response;
  };

  const fetchPopup = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/popup/web/${organizationId}`
    );
    return response;
  };

  const fetchNewsDetailById = async (newsId) => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/newsAndUpdatesById/web/${organizationId}/${newsId}`
    );
    return response;
  };

  const fetchServices = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/ourServices/web/${organizationId}`
    );
    return response;
  };

  const fetchServiceDetailById = async (serviceId) => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/ourServicesById/web/${organizationId}/${serviceId}`
    );
    return response;
  };

  const fetchOrganizationDetails = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/v2/sms/fetchOrganizationDetailsWeb/${organizationId}`
    );
    return response;
  };

  const fetchScheduleList = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/nrw/wdMasterList/${organizationId}`
    );
    return response;
  };

  const fetchScheduleById = async (id) => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/nrw/v2/wdScheduleList/${organizationId}/${id}`
    );
    return response;
  };

  const fetchOutStanding = async (customerNo) => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/outstandingForWebSite/${organizationId}/${customerNo}`
    );
    return response;
  };

  const fetchReadingScheduleList = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/meterReadingSchedule/${organizationId}`
    );
    return response;
  };

  const fetchPaymentGatewayList = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/admin/paymentGateWayURL/${organizationId}`
    );
    return response;
  };

  const fetchComplainTypes = async () => {
    const response = await axios.get(
      `WaterTariffSystem-web/webresources/customer/complainTypesForWebsite/${organizationId}`
    );
    return response;
  };

  const complainEntry = async (data) => {
    const response = await axios.post(
      `/WaterTariffSystem-web/webresources/customer/saveComplainEntry`,
      data
    );
    return response;
  };

/*  const CheckImage = async (path) => {
    return await axios.get(path)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
  }*/


  // export function CheckImage(path) {
  //   axios
  //       .get(path)
  //       .then(() => {
  //         return true;
  //       })
  //       .catch(() => {
  //         return false;
  //       });
  // }

  return {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchDownloads,
    fetchMessages,
    fetchPopup,
    fetchNewsDetailById,
    fetchServices,
    fetchOrganizationDetails,
    fetchScheduleList,
    fetchScheduleById,
    fetchOutStanding,
    fetchServiceDetailById,
    fetchReadingScheduleList,
    fetchPaymentGatewayList,
    fetchComplainTypes,
    complainEntry,
    fetchOrgIdByDomainName,
  };
}

// const apiService = new ApiService();

export default ApiService;
