import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import ApiService from "../../network/ApiService";
import "./Navbar.css";
import NepaliFlag from './svgs/np.svg';
import EnglishFlag from './svgs/us.svg';

function Navbar() {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchMessages,
    } = ApiService();
    const {organizationId} = useSelector((state) => state.organization);
    const {orgDetailData} = useSelector((state) => state.contact);
    const [messages, setMessages] = useState([]);
    const [navbarStyle, setNavbarStyle] = useState(null);
    const [language, setLanguage] = useState('en');
    const languageStatus = sessionStorage.getItem("language");

    const fetchStaffMessages = async () => {
        try {
            const response = await fetchMessages();
            setMessages(response.data.mapOfIdAndDisplayLabels);
        } catch (error) {
            console.log(error.message);
        }
    };

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    const handleShowHideDropdown = () => {
        select('#navbar').classList.toggle('navbar-mobile')
    }

    const handleMobileView = () => {
        select('#navbar').classList.toggle('navbar-mobile')
    }

    const handleShowDropdown = (event) => {
        if (select('#navbar').classList.contains('navbar-mobile')) {
            if (event.target.nextElementSibling !== null) {
                event.target.nextElementSibling.classList.toggle('dropdown-active')
            } else {
                select('#navbar').classList.toggle('navbar-mobile')
                event.target.parentElement.parentElement.classList.toggle('dropdown-active')
            }
        }
    }

    useEffect(() => {
        if (organizationId) {
            fetchStaffMessages();
        }
    }, [organizationId]);

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 200) {
            setNavbarStyle({
                backgroundColor: "#0f0f0f",
            });
        } else {
            setNavbarStyle(null);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, {passive: true});

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const changeLanguage = (language: 'en') => {
        const languageStatus = sessionStorage.getItem("language");
        if (languageStatus !== '') {
            setLanguage(language);
            sessionStorage.setItem("language", language);
        }
    }

    return (
        <header id="header" className="fixed-top" style={navbarStyle}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-10 d-flex align-items-center justify-content-lg-between">
            <Link to="/" className="logo me-auto me-lg-0">
              <img
                  src={
                      orgDetailData &&
                      orgDetailData.logoPath &&
                      orgDetailData.logoPath !== ""
                          ? orgDetailData.logoPath
                          : null
                  }
                  alt=""
                  className="img-fluid"
              />
            </Link>

            <nav id="navbar" className="navbar order-last order-lg-0">
              <ul>
                <li onClick={handleShowDropdown}>
                  <NavLink className="nav-link scrollto" to="/">
                    {languageStatus === 'en' ? 'Home' : `गृहपृष्ठ`}
                  </NavLink>
                </li>
                <li className="dropdown" onClick={handleShowDropdown}>
                  <Link to="#">
                    <span>{languageStatus === 'en' ? 'About Us' : `हाम्रो बारेमा`}</span>
                    <i className="bi bi-chevron-down"></i>
                  </Link>
                  <ul>
                    <li onClick={handleShowDropdown}>
                      <Link to="/about">{languageStatus === 'en' ? 'Office Information' : `संस्थाको जानकारी`}</Link>
                    </li>
                      {Object.keys(messages).length > 0
                          ? Object.keys(messages).map((key) => {
                              return (
                                  <li key={key}>
                              {/*{console.log(*/}
                                      {/*  messages[key].designationNP,*/}
                                      {/*  "messs"*/}
                                      {/*)}*/}
                                      <Link to={`/messages/${key}`}>
                                {languageStatus === 'en' ? messages[key].designation : messages[key].designationNP} {languageStatus === 'en' ? `'s Speech` : `को मन्तव्य`}
                              </Link>
                            </li>
                              );
                          })
                          : null}
                      {/* <li onClick={handleShowDropdown}>
                      <NavLink to="/message">अध्यक्षको मन्तव्य</NavLink>
                    </li>
                    <li onClick={handleShowDropdown}>
                      <a href="manager.php">कार्यालय प्रमुखको मन्तव्य</a>
                    </li>
                    <li onClick={handleShowDropdown}>
                      <a href="committeemembers.php">संचालक समिति</a>
                    </li>
                    <li onClick={handleShowDropdown}>
                      <a href="staffs.php">कर्मचारी विवरण</a>
                    </li> */}
                  </ul>
                </li>
                <li onClick={handleShowDropdown}>
                  <NavLink className="nav-link scrollto" to="/news">
                    {languageStatus === 'en' ? 'News & Notices' : `सुचना/गतिविधि`}
                  </NavLink>
                </li>
                <li className="dropdown" onClick={handleShowDropdown}>
                  <Link to="/#">
                    <span>{languageStatus === 'en' ? 'E-Services' : `ई-सर्विसेस`}</span>
                    <i className="bi bi-chevron-down"></i>
                  </Link>
                  <ul>
                    <li onClick={handleShowDropdown}>
                      <Link to="/our-services">{languageStatus === 'en' ? `Our Services` : `हाम्रो सेवाहरु`}</Link>
                    </li>
                     <li onClick={handleShowDropdown}>
                      <Link to="/outstanding-report">{languageStatus === 'en' ? 'Outstanding Details' : `बक्यौता विवरण`}</Link>
                    </li>
                    <li onClick={handleShowDropdown}>
                      <Link to="/complain-service">{languageStatus === 'en' ? 'Complain Register' : `गुनासो सेवा`}</Link>
                    </li>
                    <li onClick={handleShowDropdown}>
                      <Link to="/water-schedule">{languageStatus === 'en' ? 'Water Distribution Schedule' : `पानी बितरण तालिका`}</Link>
                    </li>
                    <li onClick={handleShowDropdown}>
                      <Link to="/meter-reading-schedule">{languageStatus === 'en' ? 'Meter Reading Schedule' : `रिडिङ्ग तालिका`}</Link>
                    </li>
                  </ul>
                </li>
                <li onClick={handleShowDropdown}>
                  <NavLink className="nav-link scrollto" to="/download">
                    {languageStatus === 'en' ? 'Download' : `डाउनलोड`}
                  </NavLink>
                </li>
                <li onClick={handleShowDropdown}>
                  <NavLink className="nav-link scrollto " to="/gallery">
                    {languageStatus === 'en' ? 'Photo Gallery' : `फोटो ग्यालरी`}
                  </NavLink>
                </li>
                <li onClick={handleShowDropdown}>
                  <NavLink className="nav-link scrollto " to="/team">
                    {languageStatus === 'en' ? 'Members' : `सदस्य`}
                  </NavLink>
                </li>
                <li onClick={handleShowDropdown}>
                  <NavLink className="nav-link scrollto" to="/contact">
                    {languageStatus === 'en' ? 'Contact' : `सम्पर्क`}
                  </NavLink>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle" onClick={() => handleMobileView()}></i>
            </nav>
              {/*<NavLink to="/team" className="get-started-btn scrollto">*/}
              {/*  सदस्य*/}
              {/*</NavLink>*/}
              <div className="language_buttons">
              <Link
                  onClick={() => changeLanguage('en')}
                  className=""
              >
                  <button
                      className=""
                      style={{
                          cursor: 'pointer',
                          border: languageStatus === 'en' ? '2px solid white' : ''
                      }}
                  >
                      <img
                          src={EnglishFlag}
                          width="24"
                          height="24"
                          className="el-image uk-svg uk-preserve"
                          alt=""
                          uk-svg=""
                          hidden=""
                      />
                  </button>
              </Link>
              <Link onClick={() => changeLanguage('np')} className="uk-link uk-link-reset uk-margin-small-left">
                  <button
                      className="uk-button-link uk-border-rounded uk-box-shadow-small"
                      style={{
                          cursor: 'pointer',
                          border: languageStatus === 'np' ? '2px solid white' : ''
                      }}
                  >
                          <img
                              src={NepaliFlag}
                              width="24"
                              height="24"
                              className="el-image uk-svg uk-preserve"
                              alt=""
                              uk-svg=""
                              hidden=""
                          />
                  </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
    );
}

export default Navbar;
