import React from "react";
import Hero from "../pages/Hero";
import Navbar from "./Navbar";

function Header() {
  return (
    <div>
      <Navbar />
      <Hero />
    </div>
  );
}

export default Header;
