import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import ApiService from "../../../../network/ApiService";
import AsyncSelect from "react-select/async";

function Complain() {
  const { fetchComplainTypes, complainEntry } = ApiService();
  const { organizationId } = useSelector((state) => state.organization);
  const languageStatus = sessionStorage.getItem("language");
  const [inputData, setInputData] = useState({
    orgId: organizationId,
    complainTypeId: null,
    complainDescription: "",
    complainersName: "",
    complainersNumber: "",
    complainersAddress: "",
  });

  const [options, setOptions] = useState([]);

  const getComplaintypes = async () => {
    try {
      const response = await fetchComplainTypes();
      setOptions(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChange = (e) => {
    // console.log(e.value, "changed");
    //   return;
    const list = { ...inputData };
    if (e.target) {
      const { name, value } = e.target;
      list[name] = value;
    } else {
      list["complainTypeId"] = e.value;
    }
    // console.log(list, "change");
    setInputData(list);
  };

  const submitComplain = async () => {
    try {
      const response = await complainEntry(inputData);
      setInputData({
        orgId: organizationId,
        complainTypeId: null,
        complainDescription: "",
        complainersName: "",
        complainersNumber: "",
        complainersAddress: "",
      });
      languageStatus === 'en' ?
          toast.success("Complain Submitted Successfully")
          : toast.success("उजुरी सफलतापूर्वक पेस गरियो");
    } catch (error) {
      languageStatus === 'en' ?
          toast.success("Something went wrong")
          : toast.success("केहि गलत भयो");
    }
  };
  useEffect(() => {
    if (organizationId) {
      getComplaintypes();
    }
  }, [organizationId]);

  return (
    <section className="section-padding mt-3">
      <div className="conftainer">
        <div className="col-lg-12 col-12 text-center mb-4">
          <h4>{ languageStatus === 'en' ? `Complain Register` : `गुनासो सेवा`}</h4>
        </div>
        <div className="col-md-10 m-auto card">
          <div className="card-header text-center fs-6 fw-bold">
            { languageStatus === 'en' ? `File a complaint` : `गुनासो भर्नुहोस्`}
          </div>
          <div className="card-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="name" className="form-label">
                    {languageStatus === 'en' ? `Name` : `नाम`}
                  </label>
                  <input
                    type="text"
                    name="complainersName"
                    value={inputData.complainersName}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="name"
                    placeholder={languageStatus === 'en' ? `Name` : `नाम`}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="address" className="form-label">
                    {languageStatus === 'en' ? `Address` : `ठेगाना`}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="complainersAddress"
                    value={inputData.complainersAddress}
                    onChange={(e) => handleChange(e)}
                    id="address"
                    placeholder={languageStatus === 'en' ? `Address` : `ठेगाना`}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="contact" className="form-label">
                    {languageStatus === 'en' ? `Contact No.` : `सम्पर्क नम्बर`}
                  </label>
                  <input
                    type="text"
                    name="complainersNumber"
                    value={inputData.complainersNumber}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="contact"
                    placeholder={languageStatus === 'en' ? `Contact No.` : `सम्पर्क नम्बर`}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="complainTypeId" className="form-label">
                    {languageStatus === 'en' ? `Select Complain Type` : `गुनासो प्रकार छान्नुहोस्`}
                  </label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    options={options}
                    name="complainTypeId"
                    // value={inputData.complainTypeId}
                    onChange={(e) => handleChange(e)}
                    id="complainTypeId"
                    placeholder={languageStatus === 'en' ? `Select Complain Type` : `गुनासो प्रकार छान्नुहोस्`}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="description" className="form-label">
                    {languageStatus === 'en' ? `Complain Description` : `गुनासो विवरण`}
                  </label>
                  <textarea
                    className="form-control"
                    id="description"
                    name="complainDescription"
                    value={inputData.complainDescription}
                    onChange={(e) => handleChange(e)}
                    rows={5}
                    placeholder={languageStatus === 'en' ? `Complain Description` : `गुनासो विवरण`}
                  ></textarea>
                </div>
                <div className="col-md-6 mt-5">
                  <button
                    type="submit"
                    className="btn btn-success btn-sm get-started-btn"
                    // style={{ float: "right" }}
                    onClick={() => submitComplain()}
                  >
                    {languageStatus === 'en' ? `Submit` : `पेस गर्नुहोस`}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Complain;
