import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ApiService from "../../../network/ApiService";

function NewsSlice(props) {
  const { organizationId } = useSelector((state) => state.organization);
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
  } = ApiService();

  const [newsList, setNewsList] = useState([]);
  const [splicedNews, setSplicedNews] = useState([]);
  const getNewsList = async () => {
    try {
      const response = await fetchNewsUpdates();
      setNewsList(response.data.aboutUsNewsAndImg);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId) {
      getNewsList();
    }
  }, [organizationId]);

  useEffect(() => {
    // if (splicedNews.length === 0) {
    if (props.newsDetails && props.newsDetailId) {
      const newNewsList = [...newsList];
      const filteredList = newNewsList.filter((news) => {
        return news.newsId !== Number(props.newsDetailId);
      });

      if (filteredList.length > 2) {
        const splicedNews = filteredList.splice(2);
        setSplicedNews(splicedNews);
      }
      }
    // }
  }, [newsList, props.newsDetailId]);

  return (
    <section className="ftco-section ftco-no-pb ftco-no-pt mt-3">
      <div className="container">
        <div className={props.newsDetails ? "text-left" : "page-heading"}>
          <h3 className="heading-text">News And Updates</h3>
        </div>
        <div className="row d-flex">
          {newsList && newsList.length > 0
            ? newsList.map((data, index) => {
                return (
                  <div className="col-md-12 d-flex" key={index}>
                    <div
                      className="blog-entry justify-content-end"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="text text-center">
                        <Link
                          to={`/news-detail/${data.newsId}`}
                          className="block-20 img d-flex align-items-center"
                          style={{
                            backgroundImage: `url(${
                              data.imageUrl != "n/a"
                                ? data.imageUrl
                                : "assets/images/placeholder.jpg"
                            })`,
                            borderRadius: "5px",
                            border: "1px solid #eb5d1e",
                          }}
                        ></Link>
                        <div className="d-flex justify-content-between">
                          <div className="heading mb-3 text-left">
                            <Link to={`/news-detail/${data.newsId}`}>
                              {data.header}
                            </Link>
                          </div>
                          <div className="">
                            <span>{data.newsDate}</span>
                          </div>
                        </div>
                        <div
                          className="text-justify"
                          dangerouslySetInnerHTML={parseHtmlToView(
                            // data.message.substring(0, 300)
                            data.message
                              .split("<p><br></p>")
                              .join("")
                              .substring(0, 200)
                          )}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </section>
  );
}

export default NewsSlice;
