import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import ApiService from "../../../network/ApiService";
import News from "./News";
import NewsSlice from "./NewsSlice";
import engToNepNumber from "../../../helpers/EngToNepNumber";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Lightbox from "yet-another-react-lightbox";

function NewsDetail() {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchMessages,
        fetchNewsDetailById,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [newsDetail, setNewsDetail] = useState({});
    const [newsList, setNewsList] = useState([]);
    const [open, setOpen] = useState(false);
    const [slicedNews, setSlicedNews] = useState([]);
    const [slides, setSlides] = useState([]);
    const params = useParams();
    const newsId = params.newsId;
    const getNewsDetails = async () => {
        try {
            const response = await fetchNewsDetailById(newsId);
            setNewsDetail(response.data.aboutUsNewsAndImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getNews = async () => {
        try {
            const response = await fetchNewsUpdates();
            setNewsList(response.data.aboutUsNewsAndImg);
        } catch (error) {
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId && newsId) {
            getNewsDetails();
        }
    }, [organizationId, newsId]);

    useEffect(() => {
        getNews();
    }, [newsDetail]);

    useEffect(() => {
        if (newsList.length > 0 && newsId) {
            const newList = [...newsList];
            const filtered = newList.filter((item) => {
                return item.newsId !== Number(newsId);
            });

            if (filtered.length > 3) {
                filtered.length = 3;
            }
            setSlicedNews(filtered);
        }
    }, [newsList]);

    const handleViewSlides = (galleryImages) => {
        const images = [];
        galleryImages.map((value, item) => {
            images.push({
                src: value,
                alt: "image",
                width: 3840,
                height: 2560,
            });
        });
        setSlides(images);
        setOpen(!open);
        // console.log(images, "images");
    };

    // const IMAGEURL = process.env.REACT_APP_IMAGE_URL;

    return (
        <section id="services" className="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-12 mt-3">
            <div className="section-title sticky_header">
              <h2>{languageStatus === 'en' ? 'News and Notices' : `सूचना तथा गतिबिधि`}</h2>
            </div>
              {newsDetail.length > 0 ? (
                  <div className="news-block">
                <div className="news-block-top">
                  <a href="#">
                    <img
                        src={
                            newsDetail[0].imageUrl !== "n/a"
                                ? newsDetail[0].imageUrl
                                : `assets/images/placeholder.jpg`
                        }
                        className="news-image img-fluid"
                        alt=""
                    />
                  </a>
                </div>
                      {/* <div className="news-category-block">
                  <a href="#" className="category-block-link">
                    जीवनशैली,
                  </a>

                  <a href="#" className="category-block-link">
                    पिउने पानी
                  </a>
                </div> */}
                      {/*</div>*/}
                      <div className="news-block-info">
                  <div className="d-flex mt-2">
                    <div className="news-block-date">
                      <p>
                        <i className="bi-calendar4 custom-icon me-1"></i>
                          {newsDetail[0] ? (languageStatus === 'en' ? newsDetail[0].newsDateEn : engToNepNumber(newsDetail[0].newsDate)) : null}
                      </p>
                    </div>
                      {/*<div className="news-block-author mx-5">*/}
                      {/*  <p>*/}
                      {/*    <i className="bi-person custom-icon me-1"></i>*/}
                      {/*    {languageStatus === 'en' ? `By` : ''} {newsDetail[0] ? newsDetail[0].createdBy : null} {languageStatus === 'np' ? `द्वारा` : ''}*/}
                      {/*  </p>*/}
                      {/*</div>*/}
                      {/* <div className="news-block-comment">
                    <p>
                      <i className="bi-chat-left custom-icon me-1"></i>
                      32 Comments
                    </p>
                  </div> */}
                  </div>

                  <div className="news-block-title mb-2">
                    <h4>
                      <a href="#" className="news-block-title-link">
                        {newsDetail[0] ? (languageStatus === 'en' ? (newsDetail[0].header ?? newsDetail[0].headerNp) : (newsDetail[0].headerNp ?? newsDetail[0].header)) : null}
                      </a>
                    </h4>
                  </div>

                  <div className="news-block-body">
                    <p
                        dangerouslySetInnerHTML={parseHtmlToView(
                            (languageStatus === 'en' ? ((newsDetail[0].message && newsDetail[0].message !== '') ? newsDetail[0].message : newsDetail[0].messageNp) : ((newsDetail[0].messageNp && newsDetail[0].messageNp !== '') ? newsDetail[0].messageNp : newsDetail[0].message))
                        )}
                    ></p>
                  </div>

                          {
                              (newsDetail[0].galleryUrls).length > 0 ?
                                  <>
                                      <div className="d-flex flex-column">
                                          <h3 className="text-center">{languageStatus === 'en' ? `Related Images` : 'सम्बन्धित चित्रहरू'}</h3>
                                          <div className="d-flex justify-content-center">
                                              <Lightbox
                                                  open={open}
                                                  close={() => setOpen(!open)}
                                                  slides={slides}
                                                  plugins={[Thumbnails]}
                                                  carousel={{finite: true}}
                                              />
                                              {
                                                  (newsDetail[0].galleryUrls).length > 0 ?
                                                      <>
                                                          <div
                                                              className="card col-6 mb-5 news-gallery-image m-1 overflow-hidden"
                                                              onClick={() => handleViewSlides(newsDetail[0].galleryUrls)}
                                                          >
                                                              <img
                                                                  src={
                                                                      newsDetail[0].galleryUrls && newsDetail[0].galleryUrls !== "n/a"
                                                                          ? newsDetail[0].galleryUrls
                                                                          : "assets/images/placeholder.jpg"
                                                                  }
                                                              />
                                                          </div>
                                                      </> : null
                                              }
                                          </div>
                                      </div>
                                  </> : null
                          }
                      </div>
                  </div>
              ) : null}
          </div>

          <div className="col-lg-4 col-12">
            {/* <h5 className="mt-5 mb-3">भर्खरको समाचार</h5> */}
              {slicedNews.length > 0 ? (
                  <div className="container mt-3">
                <div className="section-title">
                  <h2>{languageStatus === 'en' ? `Latest News` : `ताजा समाचार`}</h2>
                </div>

                <div className="row">
                  {slicedNews.map((data, index) => {
                      return (
                          <div
                              className="col-lg-12 col-md-6 d-flex align-items-stretch mt-3"
                              key={index}
                          >
                        <div className="icon-box">
                          <Link
                              to={`/news-detail/${data.newsId}`}
                              style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                          >
                            <img
                                src={
                                    data.imageUrl !== "n/a"
                                        ? data.imageUrl
                                        : `../assets/images/placeholder.jpg`
                                }
                                className="news-images"
                            />
                            <h4>{languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}</h4>
                              {/*<p*/}
                              {/*  dangerouslySetInnerHTML={parseHtmlToView(*/}
                              {/*    (languageStatus === 'en' ? ((data.message && data.message != '') ? data.message : data.messageNp) : ((data.messageNp && data.messageNp != '') ? data.messageNp : data.message))*/}
                              {/*      // .split("...")*/}
                              {/*      // .join("")*/}
                              {/*      .substring(0, 100)*/}
                              {/*  )}*/}
                              {/*></p>*/}
                          </Link>
                        </div>
                      </div>
                      );
                  })}
                </div>
              </div>
              ) : null}
          </div>
        </div>
      </div>
    </section>
    );
}

export default NewsDetail;
