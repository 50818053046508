import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Footer() {
  const { contactListData, orgDetailData } = useSelector(
    (state) => state.contact
  );
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [orgDetail, setOrgDetail] = useState(null);
  const languageStatus = sessionStorage.getItem("language");

  useEffect(() => {
    if (contactListData && Object.keys(contactListData).length > 0) {
      setPhone(contactListData.find((item) => item.contactType === "Phone"));
      setEmail(contactListData.find((item) => item.contactType === "Email"));
    }
  }, [contactListData]);

  useEffect(() => {
    if (orgDetailData && Object.keys(orgDetailData).length > 0) {
      setOrgDetail(orgDetailData);
    }
  }, [orgDetailData]);

  // const parseHtmlToView = (str) => {
  //   return { __html: str };
  // };

  return (
      <>
            <footer id="footer">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 text-start">
                    <h4>{languageStatus === 'en' ? `Contact` : `सम्पर्क`}</h4>
                    <p>
                      {orgDetail ? (languageStatus === 'en' ? (orgDetail.nameEnglish ?? orgDetail.nameNepali) : (orgDetail.nameNepali ?? orgDetail.nameEnglish)) : null} <br />
                      {orgDetail ? (languageStatus === 'en' ? (orgDetail.addressEnglish ?? orgDetail.addressNepali) : (orgDetail.addressNepali ?? orgDetail.addressEnglish)) : null} <br />
                      <a href={`tel:${orgDetail ? orgDetail.contactNumberNp : ""}`}>
                        {orgDetail ? (languageStatus === 'en' ? (orgDetail.contactNumber ?? orgDetail.contactNumberNp) : (orgDetail.contactNumberNp ?? orgDetail.contactNumber)) : null}
                      </a>
                      <br />
                      {/* <a href="tel:+977-9801480758">९८०१४८०७५८</a> <br /> */}
                      {email ? (
                        <a href={`mailto:${email.contactAddress}`}>
                          {email.contactAddress}
                        </a>
                      ) : null}
                    </p>
                  </div>
                  <div className="col-md-3 text-start">
                    <h4>{languageStatus === 'en' ? `Link` : `लिङ्क` }</h4>
                    <ul>
                      <li>
                        <Link to="/about">{languageStatus === 'en' ? `Organization Information` : `संस्थाको जानकारी` }</Link>
                      </li>
                      <li>
                        <Link to="/news">{languageStatus === 'en' ? `News & Notices` : `सुचना/गतिविधि` }</Link>
                      </li>
                      <li>
                        <Link to="/contact">{languageStatus === 'en' ? `Contact` : `सम्पर्क` }</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3 text-start">
                    <h4>{languageStatus === 'en' ? `Other Links` : `अतिरित्त लिङ्क` }</h4>
                    <ul>
                      <li>
                        <a href="http://www.mowss.gov.np/" target="_blank">
                          {languageStatus === 'en' ? `Ministry of Water Supply` : `खानेपानी मन्त्रालय` }
                        </a>
                      </li>
                      <li>
                        <a href="http://www.nwsc.gov.np/" target="_blank">
                          {languageStatus === 'en' ? `Department of Water Supply` : `खानेपानी बिभाग` }
                        </a>
                      </li>
                      <li>
                        <a href="http://www.tdf.org.np/" target="_blank">
                          {languageStatus === 'en' ? `Municipal Development Fund` : `नगर विकास कोष` }
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/*<div className="col-md-3 text-start">*/}
                  {/*  <h4>व्यबस्थापक लिङ्क</h4>*/}
                  {/*  <ul>*/}
                  {/*    <li>*/}
                  {/*      <Link to="/team">व्यबस्थापक प्यानल</Link>*/}
                  {/*    </li>*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                </div>
                <div className="social-links">
                  <a href={orgDetail ? orgDetail.twitterUrl : ""} className="twitter">
                    <i className="bx bxl-twitter"></i>
                  </a>
                  <a href={orgDetail ? orgDetail.facebookUrl : ""} className="facebook">
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a href={orgDetail ? orgDetail.linkedInUrl : ""} className="linkedin">
                    <i className="bx bxl-linkedin"></i>
                  </a>
                  <a href={orgDetail ? orgDetail.youtubeUrl : ""} className="youtube">
                    <i className="bx bxl-youtube"></i>
                  </a>
                </div>
                <div className="row">
                  <div className="col-md-6 text-start">
                    <div className="copyright">
                      &copy; {new Date().getFullYear()}{" "}
                      { orgDetail ? (languageStatus === 'en' ? (`All Rights Reserved ` + orgDetail.nameEnglish) : (orgDetail.nameNepali + `| सम्पूर्ण अधिकार सुरक्षित`)) : null }
                    </div>
                  </div>
                  <div className="col-md-6 text-end">
                    <div className="credits">
                      {languageStatus === 'en' ? 'Designed and Developed By : ' : `व्यबसाईट निर्माता : ` } <a target="_blank" href="https://diyalotech.com/">{languageStatus === 'en' ? `Diyalo Technologies`: ` दियालो टेक्नोलोजी`}</a>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          <style type="text/css">
            { orgDetailData?.customCss}
          </style>
        </>
  );
}

export default Footer;
