import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "./Gallery.css";
import "yet-another-react-lightbox/styles.css";
import ApiService from "../../../network/ApiService";
import {useSelector} from "react-redux";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function Gallery(props) {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
    } = ApiService();
    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([
        {
            src: "https://cdn.pixabay.com/photo/2016/12/17/14/33/wave-1913559_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2016/04/15/04/02/water-1330252_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2016/11/06/05/36/lake-1802337_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2013/10/09/02/27/lake-192990_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2014/10/26/17/19/fisherman-504098_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2016/10/22/15/32/water-1761027_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
    ]);

    const [galleryData, setGalleryData] = useState([]);

    const fetchGalleryImages = async () => {
        try {
            const response = await fetchGalleries();
            setGalleryData(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleViewSlides = (galleryImages) => {
        const images = [];
        galleryImages.map((value, item) => {
            images.push({
                src: value,
                alt: "image",
                width: 3840,
                height: 2560,
            });
        });
        setSlides(images);
        setOpen(!open);
        // console.log(images, "images");
    };

    // useEffect(()=>{
    //   if (slides.length > 0) {

    //   }
    // },[slides])

    useEffect(() => {
        if (organizationId) {
            fetchGalleryImages();
        }
    }, [organizationId]);

    return (
        <main id="main">
      <section id="about" className="about">
        <div className="container">
          <div className="section-title sticky_header" style={{ width: props.sidebarwidth }}>
            <h2 className="font-bold" style={{ color: '#0086ff'}}>{languageStatus === 'en' ? `Photo Gallery` : `फोटो ग्यालरी`}</h2>
          </div>
          <div className="row col-md-12">
              <Lightbox
                  open={open}
                  close={() => setOpen(!open)}
                  slides={slides}
                  plugins={[Thumbnails]}
                  carousel={{finite: true}}
              />
              {galleryData.length > 0
                  ? galleryData.map((data, index) => {
                      return (
                          <div
                              className="card col-2 mb-5 gallery-image m-1"
                              key={index}
                              onClick={() => handleViewSlides(data.galleryUrls)}
                          >
                            <img
                                src={
                                    data.imageUrl && data.imageUrl !== "n/a"
                                        ? data.imageUrl
                                        : "assets/images/placeholder.jpg"
                                }
                            />
                            <div className=" mt-2 img-label text-center">
                              <span className="text-center fw-bold" style={{ color: '#0086ff'}}>{languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}</span>
                            </div>
                          </div>
                      );
                  })
                  : <>
                    <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                      <div
                          className="col-md-12"
                          style={{fontSize: '24px'}}
                      >
                        {languageStatus === 'en' ? `Photo Gallery Not Found` : `फोटो ग्यालरी भेटियन`}...
                      </div>
                    </div>
                </>}
          </div>
        </div>
      </section>
    </main>
    );
}

export default Gallery;
